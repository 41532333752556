<!--  -->
<template>
    <div align="center" class='holter-form'>
        <div id="DomPdf" style="width:794px;height:1123px;">
        <div>
          <span style="display: inline-block; font-size: 24px">
            八导联24小时心电检测报告
          </span>
          <img style="width: 100px; vertical-align: middle; display: inline-block"
            src="../../../../public/img/logo_xingou.png" />
        </div>
        <el-row style="border:solid 2px;">
            <div style="padding:5px;background-color:rgb(211, 211, 211);font-size: 20px;font-weight: bold;">患者信息</div>
            <el-descriptions border :labelStyle="infoStyle">
            <el-descriptions-item label="姓名">zyf</el-descriptions-item>
            <el-descriptions-item label="性别">男</el-descriptions-item>
            <el-descriptions-item label="年龄">28</el-descriptions-item>
            <el-descriptions-item label="时间" span="2">
            2022年7月9日 17点16分至2022年7月9日 17点16分
            </el-descriptions-item>
            <el-descriptions-item label="总时长">0时1分0秒</el-descriptions-item>
        </el-descriptions>
        </el-row>
        <!-- <br> -->
        <el-row style="border:solid 2px;border-bottom: none;border-top: none;">
        <div style="padding:5px;background-color:rgb(211, 211, 211);font-size: 20px;font-weight: bold;">分析结果统计</div>
        </el-row>
        <el-row >
        <el-col :span="8" style="border-top:solid 2px;border-left:solid 2px;border-bottom:solid 1px;border-right:solid 1px;">
            <el-descriptions title="概述"  :column="1" border  :labelStyle="labelStyle">
            <el-descriptions-item v-for="item in overview" :key="item.label" :label="item.label">
                {{item.value}}
            </el-descriptions-item>
        </el-descriptions>
        </el-col>
        <el-col :span="8" style="border:solid 1px;border-top: solid 2px; border-bottom:solid 1px" >
            <el-descriptions title="心率" :column="1" border :labelStyle="labelStyle">
            <el-descriptions-item v-for="item in heartRate" :key="item.label" :label="item.label" class="description-item">
                {{item.value}}
            </el-descriptions-item>
        </el-descriptions>
        </el-col>
        <el-col :span="8" style="border-top:solid 2px;border-right:solid 2px;border-left:solid 1px;border-bottom: solid 1px;">
            <el-descriptions title="心率变异" :column="1" border :labelStyle="labelStyle">
            <el-descriptions-item v-for="item in heartRateAbnorm" :key="item.label" :label="item.label">
                {{item.value}}
            </el-descriptions-item>
        </el-descriptions>
        </el-col>
        </el-row>
    
        <el-row>
        <el-col :span="8" style="border-bottom:solid 1px;border-left:solid 2px;border-top:solid 1px;border-right:solid 1px; height:290px ">
            <el-descriptions title="室性节律" :column="1" border :labelStyle="labelStyle">
            <el-descriptions-item v-for="item in sinusRhythm" :key="item.label" :label="item.label">
                {{item.value}}
            </el-descriptions-item>
        </el-descriptions>
        </el-col>
        <el-col :span="8" style="border:solid 1px;height:290px ">
            <el-descriptions title="室上性节律" :column="1" border :labelStyle="labelStyle">
            <el-descriptions-item v-for="item in supraventricularRhythm" :key="item.label" :label="item.label">
                {{item.value}}
            </el-descriptions-item>
        </el-descriptions>
        </el-col>
        <el-col :span="8" style="border-bottom:solid 1px;border-right:solid 2px;border-top:solid 1px; border-left:solid 1px;height:290px ">
            <el-descriptions title="停搏" :column="1" border :labelStyle="labelStyle">
            <el-descriptions-item v-for="item in asystole" :key="item.label" :label="item.label">
                {{item.value}}
            </el-descriptions-item>
            </el-descriptions>
            <br>
            <el-descriptions title="QT" :column="1" border :labelStyle="labelStyle">
            <el-descriptions-item v-for="item in qt" :key="item.label" :label="item.label">
                {{item.value}}
            </el-descriptions-item>
        </el-descriptions>
        </el-col>
        </el-row>
    
        <el-row>
            <el-col :span="24" style="border:solid 2px;border-top: solid 1px;" >
                <el-table
                    :data="tableData" :header-row-style="{ height: '30px' }" :header-cell-style="{ padding: '0px' }"
                    :row-style="{height:'20px'}" :cell-style="{ padding: '0px' }"
                    style="width: 100%; height: 100%">
                    <el-table-column
                        prop="category"
                        label="类别"
                        width="180">
                    </el-table-column>
                    <el-table-column
                        prop="aVF"
                        label="aVF"
                        width="180">
                    </el-table-column>
                    <el-table-column
                        prop="v5"
                        label="v5"
                        width="180">
                    </el-table-column>
                    <el-table-column
                        prop="v1"
                        label="v1">
                    </el-table-column>
                </el-table>
            </el-col>
        </el-row>
    
        <!-- <br> -->
        <el-row style="border:solid 2px;border-bottom: none;border-top: none;">
        <div style="padding:5px;background-color:rgb(211, 211, 211);font-size: 20px;font-weight: bold;">结论</div>
        </el-row>
        <el-row>
            <el-col :span="24" style="border:solid 2px;border-top: none;">
                <el-descriptions :column="1" class="description-item" border :labelStyle="labelStyle">
                    <el-descriptions-item v-for="item in conclusion" :key="item.label" :label="item.label">
                        {{item.value}}
                    </el-descriptions-item>
                </el-descriptions>
            </el-col>
        </el-row>
        </div>
    
        <el-button type="primary" plain @click="getPdf('#DomPdf')">下载心电报告</el-button>
    
        
    </div>
    </template>
    
    <script>
    //这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
    //例如：import 《组件名称》 from '《组件路径》';
    
    export default {
    //import引入的组件需要注入到对象中才能使用
    components: {},
    data() {
    //这里存放数据
    return {
        overview: [{label:'分析时间', value:'00:59'}, {label:'心搏总数', value:'4873'}, {label:'起搏心搏', value:'-'}, {label:'室性心搏', value:'0'}
                    ,{label:'室上性心搏', value:'0'},{label:'BBB', value:'0'},{label:'异常心搏', value:'-'},{label:'房扑/房颤占时比(%)', value:''}],
        heartRate: [{label:'平均心率', value:'00:59'}, {label:'最慢心率', value:'4873'}, {label:'最快心率', value:'-'}, {label:'分钟计最慢心率', value:'0'}
                    ,{label:'分钟计最快心率', value:'0'},{label:'心动过速心搏', value:'0'},{label:'心动过缓心搏', value:'-'},{label:'最长RR间期', value:'-'}],
        heartRateAbnorm: [{label:'SDNN', value:'00:59'}, {label:'SDNN Index', value:'4873'}, {label:'RMSSD', value:'-'}, {label:'PNN50', value:'0'}
                    ,{label:'三角指数', value:'0'},{label:'HF', value:'0'},{label:'LF', value:'-'},{label:'VLF', value:'-'}],
        sinusRhythm: [{label:'单个', value:'00:59'}, {label:'成对室早', value:'4873'}, {label:'二联律室早', value:'-'}, {label:'三联律室早', value:'0'}
                    ,{label:'连续室早', value:'0'},{label:'最长连续室早', value:'0'},{label:'最快心率连续室早', value:'-'},{label:'最慢心率连续室早', value:'-'}],
        supraventricularRhythm: [{label:'单个', value:'00:59'}, {label:'成对室上早', value:'4873'}, {label:'二联律室上早', value:'-'}, {label:'三联律室上早', value:'0'}
                    ,{label:'连续室上早', value:'0'},{label:'最长连续室上早', value:'0'},{label:'最快心率连续室上早', value:'-'},{label:'心房颤动/心房扑动', value:'-'}],
        asystole: [{label:'停搏大于2秒', value:'00:59'}, {label:'最长停搏', value:'4873'}],
        qt: [{label:'最大QT', value:'-'}, {label:'最大QTc', value:'0'},{label:'平均QT', value:'0'},{label:'平均QTc', value:'0'},],
        conclusion: [{label:'心电监测', value:''}, {label:'平均心率', value:''}, {label:'最大心率', value:''}, {label:'最小心率', value:''}, {label:'室性早搏', value:''}],
        tableData: [{category: 'ST改变分钟总数', aVF:"", V5:"", V1:""}, {category: '最大ST压低mm', aVF:"", V5:"", V1:""}, {category: '最大ST抬高mm', aVF:"", V5:"", V1:""}],
        htmlTitle: '八导联24小时心电检测报告',
        labelStyle:{
            'width':'180px',
            'color':'#606266'
        },
        infoStyle:{
            'color':'#606266'
        }
    };
    },
    //监听属性 类似于data概念
    computed: {},
    //监控data中的数据变化
    watch: {},
    //方法集合
    methods: {
    
    },
    //生命周期 - 创建完成（可以访问当前this实例）
    created() {
    
    },
    //生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {
    
    },
    beforeCreate() {}, //生命周期 - 创建之前
    beforeMount() {}, //生命周期 - 挂载之前
    beforeUpdate() {}, //生命周期 - 更新之前
    updated() {}, //生命周期 - 更新之后
    beforeDestroy() {}, //生命周期 - 销毁之前
    destroyed() {}, //生命周期 - 销毁完成
    activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
    </script>
    <style lang="less" scoped>
    .holter-form /deep/ .el-descriptions :not(.is-bordered) .el-descriptions-item__cell {
        padding:5px
    }
    ::v-deep .el-descriptions__title{
        font-size:16px;
    }
    ::v-deep .el-descriptions__header {
        display: flex;
        justify-content: space-between;
        margin: 5px;
        
    }
    </style>